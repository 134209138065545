// AuthReducer
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const CLEAR_LOGIN_MESSAGE = 'CLEAR_LOGIN_MESSAGE';
// Signup Reducer 
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const CLEAR_FORGOT_PASSWORD_MESSAGE = '';
export const CLEAR_SIGNUP_MESSAGE = 'CLEAR_SIGNUP_MESSAGE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SIGNUP_LOADING = 'SIGNUP_LOADING';

//Gives Reducer 
export const SELECT_FRIEND = 'SELECT_FRIEND';
export const DESELET_FRIEND = 'DESELECT_FRIEND';
export const FETCH_GIVEN_MONEY = 'FETCH_GIVEN_MONEY';
export const FETCH_GIVEN_ITEMS = 'FETCH_GIVEN_ITEMS';
export const GIVE_MONEY = 'GIVE_MONEY';
export const GIVE_ITEM = 'GIVE_ITEM';
export const UPDATE_GIVE_ITEM = 'UPDATE_GIVE_ITEM';
export const UPDATE_GIVE_MONEY = 'UPDATE_GIVE_MONEY';
export const DELETE_BORROW_MONEY = 'DELETE_BORROW_MONEY';
export const DELETE_BORROW_ITEM = 'DELETE_BORROW_ITEM';
export const GIVES_LOADING = 'GIVES_LOADING';
export const GIVE_ERROR = 'GIVE_ERROR';
export const CLEAR_GIVE_MESSAGE = 'CLEAR_GIVE_MESSAGE';

//Takes Reducer
export const FETCH_TAKEN_MONEY = 'FETCH_TAKEN_MONEY';
export const FETCH_TAKEN_ITEMS = 'FETCH_TAKEN_ITEMS';
export const TAKES_LOADING = 'TAKES_LOADING';
export const CLEAR_TAKE_MESSAGE = 'CLEAR_TAKE_MESSAGE';
export const TAKE_ERROR = 'TAKE_ERROR';

//Token Reducer
export const GET_TOKEN = 'GET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const CLEAR_TOKEN_MESSAGE = 'CLEAR_TOKEN_MESSAGE';
export const GENERATE_TOKEN = 'GENERATE_TOKEN';
export const VALIDATE_BORROW = 'VALIDATE_BORROW';
export const REJECT_BORROW = 'REJECT_BORROW';
export const VALIDATE_RETURN = 'VALIDATE_RETURN';
export const TOKEN_ERROR = 'TOKEN_ERROR';
export const TOKEN_LOADING = 'TOKEN_LOADING';

// Reminder Reducer 
export const FETCH_SENT_REMINDERS = 'FETCH_SENT_REMINDERS';
export const FETCH_RECEIVED_REMINDERS = 'FETCH_RECEIVED_REMINDERS';
export const MARK_REMINDER_READ = 'MARK_REMINDER_READ';
export const SEND_REMINDER = 'SEND_REMINDER';
export const DELETE_SENT_REMINDER = 'DELETE_SENT_REMINDER';
export const REMINDER_ERROR = 'REMINDER_ERROR';
export const REMINDER_LOADING = 'REMINDER_LOADING';
export const CLEAR_REMINDER_MESSAGE = 'CLEAR_REMINDER_MESSAGE';

// Reset Password
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_LOADING = 'RESET_PASSWORD_REQUEST_LOADING';
export const RESET_PASSWORD_CLEAR_MESSAGE = 'RESET_PASSWORD_REQUSET_CLEAR_MESSAGE';